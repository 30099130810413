import Link from "next/link";


export const categories2 = [
  {
    id: 1,
    imageUrl: "/assets/images/home/demo3/category_1.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/07/SHOP-VIDEO-1.mp4",
    text: "Women Tops",
    category: "Oriental Fragrance",
  },
  {
    id: 2,
    imageUrl: "/assets/images/home/demo3/category_2.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/07/Ahmed-Perfume-Street-View.mp4",
    text: "Women Pants",
    category: "Occidental Fragrance",
  },
  {
    id: 3,
    imageUrl: "/assets/images/home/demo3/category_3.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/03/laathani-short.mp4",
    text: "Women Clothes",
    category: "Women Fragrances",
  },
  {
    id: 4,
    imageUrl: "/assets/images/home/demo3/category_4.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2023/01/video-animation.mp4",
    text: "Men Jeans",
    category: "Men Fragrances",
  },
  {
    id: 5,
    imageUrl: "/assets/images/home/demo3/category_5.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/07/Ahmed-Perfume-Street-View.mp4",
    text: "Men Shirts",
    category: "Unisex Fragrances",
  },
];

export const categories3 = [
  
];



export const categories8 = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Antee Gift Set",
    subText: "Whispers of Elegance",
    videoSrc: "/assets/videos/giftsets/antee.mp4",
    imgSrc2: "/assets/videos/giftsets/thumbnails/antee.jpg",
    link: "/shop/gift-sets/gift-sets/antee-gift-set-05"
  },
  {
    id: 2,
    imgSrc: "/assets/images/home/demo15/category-2.jpg",
    altText: "The Dukhoon Collection",
    subText: "Tradition Reimagined",
    videoSrc: "/assets/videos/giftsets/dakhoon-collection.mp4",
    imgSrc2: "/assets/videos/giftsets/thumbnails/dukhoon-collection.jpg",
    link: "/shop/gift-sets/gift-sets/the-dukhoon-collection"
  },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo15/category-3.jpg",
    altText: "Ihdaa Khaas Gift Set",
    subText: "Essence of Luxury",
    videoSrc: "/assets/videos/giftsets/ihdakhas.mp4",
    imgSrc2: "/assets/videos/giftsets/thumbnails/ihda-khas.jpg",
    link: "/shop/gift-sets/gift-sets/ihdaa-khaas"
  },
  {
    id: 4,
    imgSrc: "/assets/images/home/demo15/category-4.jpg",
    altText: "Oud & Roses Gift Set",
    subText: "Expression of Memories",
    videoSrc: "/assets/videos/giftsets/oud-and-roses-giftset.mp4",
    imgSrc2: "/assets/videos/giftsets/thumbnails/oud-roses-giftset.jpg",
    link: "/shop/gift-sets/gift-sets/oud-roses-gift-set"
  },
  {
    id: 5,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Shauque Al Shuyookh Gift Set",
    subText: "A Legacy of Grace",
    videoSrc: "/assets/videos/giftsets/shaquealsheukh-giftset.mp4",
    imgSrc2: "/assets/videos/giftsets/thumbnails/shauque-al-shuyookh.jpg",
    link: "/shop/gift-sets/gift-sets/shauque-al-shuyookh"
  },
  // {
  //   id: 6,
  //   imgSrc: "/assets/images/home/demo15/category-2.jpg",
  //   altText: "Gift Set 6",
  //   subText: "by Ahmed Al Maghribi Perfumer",
  //   videoSrc: "https://www.ateliercologne.com/videos/chapters/third/story/preview/oolang-infini.mp4",
  //   imgSrc2: "https://www.ateliercologne.com/images/chapters/third/fragrance/oolang-infini@1x.jpg",
  // },
  // {
  //   id: 7,
  //   imgSrc: "/assets/images/home/demo15/category-3.jpg",
  //   altText: "Gift Set 7",
  //   subText: "by Ahmed Al Maghribi Perfumer",
  //   videoSrc: "https://www.ateliercologne.com/videos/chapters/third/story/preview/pomelo-paradis.mp4",
  //   imgSrc2: "https://www.ateliercologne.com/images/chapters/third/fragrance/pomelo-paradis@1x.jpg",
  // },
  // {
  //   id: 8,
  //   imgSrc: "/assets/images/home/demo15/category-4.jpg",
  //   altText: "Gift Set 8",
  //   subText: "by Ahmed Al Maghribi Perfumer",
  //   videoSrc: "https://www.ateliercologne.com/videos/chapters/third/story/preview/clementine-california.mp4",
  //   imgSrc2: "https://www.ateliercologne.com/images/chapters/third/fragrance/clementine-california@1x.jpg",
  // },
];

export const categories88 = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Gauahar Khan",
    subText: "Exude effortless allure",
    videoSrc: "/assets/videos/vlogs/gauhar-khan.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/gauhar-khan.jpg",
    link: "#"
  },
  // {
  //   id: 2,
  //   imgSrc: "/assets/images/home/demo15/category-2.jpg",
  //   altText: "Khalid Al Ameri",
  //   subText: "Capture elegance in motion",
  //   videoSrc: "/assets/videos/vlogs/Khalid.mp4",
  //   imgSrc2: "/assets/videos/vlogs/thumbnails/Khalid.jpg",
  //   link: "#"
  // },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo15/category-3.jpg",
    altText: "Aromatix - Neeb Alwaz",
    subText: "Uncover your signature glow",
    videoSrc: "/assets/videos/vlogs/Neeb.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/Neeb.jpg",
    link: "#"
  },
  {
    id: 4,
    imgSrc: "/assets/images/home/demo15/category-4.jpg",
    altText: "Rico Fragrance",
    subText: "Radiate pure, refined luxury",
    videoSrc: "/assets/videos/vlogs/Rico.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/Rico.jpg",
    link: "#"
  },
  {
    id: 5,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Sana Khan",
    subText: "Embrace unforgettable elegance",
    videoSrc: "/assets/videos/vlogs/Sana-Khan.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/Sana-Khan.jpg",
    link: "#"
  },
  {
    id: 6,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Yasser Salah Salem",
    subText: "Embrace unforgettable elegance",
    videoSrc: "/assets/videos/vlogs/yassersalahsalem.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/yassersalahsalem.jpg",
    link: "#"
  },
  {
    id: 7,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "The Cologne Boy",
    subText: "Embrace unforgettable elegance",
    videoSrc: "/assets/videos/vlogs/The-Cologne-Boy.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/The-Cologne-Boy.jpg",
    link: "#"
  },
  {
    id: 8,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Hina Khan",
    subText: "Embrace unforgettable elegance",
    videoSrc: "/assets/videos/vlogs/Henna-Khan.mp4",
    imgSrc2: "/assets/videos/vlogs/thumbnails/Henna-Khan.jpg",
    link: "#"
  },
];

export const categoriesInfluencers = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Oud Ma'attar Asaateen",
    subText: "Oud, leather, and rose luxury",
    videoSrc: "/assets/videos/dakhoon/asateen.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/asateen.jpg",
    link: "/product-category/dakhoon"
  },
  {
    id: 2,
    imgSrc: "/assets/images/home/demo15/category-2.jpg",
    altText: "Bakhoor Hind",
    subText: "Exotic warmth of saffron",
    videoSrc: "/assets/videos/dakhoon/bakhoor-hind.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/bakhoor-hind.jpg",
    link: "/product-category/dakhoon"
  },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo15/category-3.jpg",
    altText: "Oud Ma'attar Khususi",
    subText: "Citrus and oud, pure elegance",
    videoSrc: "/assets/videos/dakhoon/khususi.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/majalis.jpg",
    link: "/product-category/dakhoon"
  },
  {
    id: 4,
    imgSrc: "/assets/images/home/demo15/category-4.jpg",
    altText: "Oud Ma'Attar Maliki",
    subText: "Royal saffron and oud bliss",
    videoSrc: "/assets/videos/dakhoon/maliki.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/maliki.jpg",
    link: "/product-category/dakhoon"
  },
  {
    id: 5,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Oud Philippine",
    subText: "Citrus, roses, and musk bliss",
    videoSrc: "/assets/videos/dakhoon/oud.mov",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/oud.jpg",
    link: "/product-category/dakhoon"
  },
];
export const categories80 = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Grace",
    subText: "Exude effortless allure",
    videoSrc: "/assets/videos/dakhoon/asateen.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/asateen.jpg",
    link: "#"
  },
  {
    id: 2,
    imgSrc: "/assets/images/home/demo15/category-2.jpg",
    altText: "Allure",
    subText: "Capture elegance in motion",
    videoSrc: "/assets/videos/dakhoon/bakhoor-hind.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/bakhoor-hind.jpg",
    link: "/shop/eau-de-parfum/oriental-fragrance/awfa"
  },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo15/category-3.jpg",
    altText: "Splendor",
    subText: "Uncover your signature glow",
    videoSrc: "/assets/videos/dakhoon/khususi.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/majalis.jpg",
    link: "/shop/eau-de-parfum/oriental-fragrance/bidun-esam"
  },
  {
    id: 4,
    imgSrc: "/assets/images/home/demo15/category-4.jpg",
    altText: "Prestige",
    subText: "Radiate pure, refined luxury",
    videoSrc: "/assets/videos/dakhoon/maliki.mp4",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/maliki.jpg",
    link: "/shop/eau-de-parfum/oriental-fragrance/bin-ameer"
  },
  {
    id: 5,
    imgSrc: "/assets/images/home/demo15/category-1.jpg",
    altText: "Aura",
    subText: "Embrace unforgettable elegance",
    videoSrc: "/assets/videos/dakhoon/oud.mov",
    imgSrc2: "/assets/videos/dakhoon/thumbnails/oud.jpg",
    link: "http://localhost:3000/shop/eau-de-parfum/oriental-fragrance/bin-shaikh"
  },
];



export const shopCategories = [
  {
    id: 1,
    imgSrc: "/assets/images/shop/category-item1.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/07/SHOP-VIDEO-1.mp4",
    imgAlt: "Women Tops",
    category: "Oriental Fragrance",
  },
  {
    id: 2,
    imgSrc: "/assets/images/shop/category-item2.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/07/Ahmed-Perfume-Street-View.mp4",
    imgAlt: "Women Pants",
    category: "Occidental Fragrance",
  },
  {
    id: 3,
    imgSrc: "/assets/images/shop/category-item3.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/03/laathani-short.mp4",
    imgAlt: "Women Clothes",
    category: "Women Fragrances",
  },
  {
    id: 4,
    imgSrc: "/assets/images/shop/category-item4.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2023/01/video-animation.mp4",
    imgAlt: "Men Jeans",
    category: "Men Fragrances",
  },
  {
    id: 5,
    imgSrc: "/assets/images/shop/category-item5.png",
    videoSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/07/Ahmed-Perfume-Street-View.mp4",
    imgAlt: "Men Shirts",
    category: "Unisex Fragrances",
  },
  // {
  //   id: 6,
  //   imgSrc: "/assets/images/shop/category-item6.png",
  //   imgAlt: "Men Shoes",
  //   category: "Men Shoes",
  // },
  // {
  //   id: 7,
  //   imgSrc: "/assets/images/shop/category-item7.png",
  //   imgAlt: "Women Dresses",
  //   category: "Women Dresses",
  // },
  // {
  //   id: 8,
  //   imgSrc: "/assets/images/shop/category-item8.png",
  //   imgAlt: "Kids Tops",
  //   category: "Kids Tops",
  // },
];
