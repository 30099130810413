export const slides1 = [
  {
    id: 1,

    characterImg: "/assets/images/home/demo5/mobile-city.jpg",
    characterAlt: "Woman Fashion 1",
    characterText: "Summer",
    text1: "New Trend",
    text2: "Summer Sale Stylish",
    text3: "Womens",
  },
  
];
export const slides2 = [
  {
    id: 1,
    imageSrc: "/assets/images/home/demo5/city-walk.jpg",
    imageAlt: "Pattern",
    imageStyle: { objectPosition: "80% center" },
    season: "",
    title: "",
    discount: "",
  },
  // {
  //   id: 2,
  //   imageSrc: "/assets/images/home/demo2/slider2.jpg",
  //   imageAlt: "Pattern",
  //   imageStyle: { objectPosition: "70% center" },
  //   season: "Summer 2023",
  //   title: "Hello New Season",
  //   discount: "Limited time offer - up to 60% off & free shipping",
  // },
  // {
  //   id: 3,
  //   imageSrc: "/assets/images/home/demo2/slider3.jpg",
  //   imageAlt: "Pattern",
  //   imageStyle: { objectPosition: "70% center" },
  //   season: "Summer 2023",
  //   title: "Hello New Season",
  //   discount: "Limited time offer - up to 60% off & free shipping",
  // },
];
export const slides3 = [
  {
    id: 1,
    imageUrl: "/assets/images/home/demo3/slideshow-character1.png",
    imageWidth: 542,
    imageHeight: 733,
    altText: "Woman Fashion 1",
    characterMarkup: "Dresses",
  },
  {
    id: 2,
    imageUrl: "/assets/images/slideshow-character1.png",
    imageWidth: 400,
    imageHeight: 733,
    altText: "Woman Fashion 1",
    characterMarkup: "Summer",
  },
  {
    id: 3,
    imageUrl: "/assets/images/slideshow-character2.png",
    imageWidth: 400,
    imageHeight: 690,
    altText: "Woman Fashion 2",
  },
];
export const slides4 = [
  {
    id: 1,
    bgImg: "/assets/images/home/demo8/top/bottles/bin-shaikh.png",
    markImg: "/assets/images/home/demo8/top/bin-shaikh.jpg",
    title: "The Classics",
    description: "An exclusive selection of this season's trends.",
    characterImgWidth: 675,
    characterImgHeight: 733,
  },
  
];
export const slides5 = [
  {
    id: 1,
    slideshowImg: "/assets/images/home/demo4/slider1.png",
    bgImg: "/assets/images/home/demo4/slider_mark.png",
    title: "The Classics",
    description: "An exclusive selection of this season's trends.",
    imgWidth: "493",
    imgHeight: "693",
    bgImgWidth: "690",
    bgImgHeight: "690",
  },
  {
    id: 2,
    slideshowImg: "/assets/images/home/demo4/slider2.png",
    bgImg: "/assets/images/home/demo4/slider_mark.png",
    title: "The Classics",
    description: "An exclusive selection of this season's trends.",
    imgWidth: "490",
    imgHeight: "690",
    bgImgWidth: "560",
    bgImgHeight: "560",
  },
  {
    id: 3,
    slideshowImg: "/assets/images/home/demo4/slider3.png",
    bgImg: "/assets/images/home/demo4/slider_mark.png",
    title: "The Classics",
    description: "An exclusive selection of this season's trends.",
    imgWidth: "675",
    imgHeight: "733",
    bgImgWidth: "690",
    bgImgHeight: "690",
  },
];

export const slides6 = [
  {
    id: 1,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/bin-shaikh.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/bin-shaikh.png",
    title: "Bin Shaikh",
  },
  {
    id: 2,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/ignite-oud.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/ignite-oud.png",
    title: "Ignite oud",
  },
  {
    id: 3,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/bin-shaikh.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/bin-shaikh.png",
    title: "Bin Shaikh",
  },
  {
    id: 4,
    bgColor: "#f5e6e0",
    imgBg: "/assets/images/home/demo8/top/ignite-oud.jpg",
    imgSrc: "/assets/images/home/demo8/top/bottles/ignite-oud.png",
    title: "Ignite oud",
  },
];
export const slidesData = [
  {
    id: 1,
    src: "/assets/images/home/demo6/slider1.jpg",
    title: "Our All-Time Favourites",
    subtitle: "Blouses & Tops",
  },
  {
    id: 2,
    src: "/assets/images/home/demo6/slider1.jpg",
    title: "Our All-Time Favourites",
    subtitle: "Blouses & Tops",
  },
  {
    id: 3,
    src: "/assets/images/home/demo6/slider1.jpg",
    title: "Our All-Time Favourites",
    subtitle: "Blouses & Tops",
  },
];
export const slidesData2 = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo7/slider1.jpg",
    title: "New In",
    width: 475,
    height: 800,
  },
  {
    id: 2,
    imgSrc: "/assets/images/home/demo7/slider2.jpg",
    title: "Coats",
    width: 475,
    height: 800,
  },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo7/slider3.jpg",
    title: "Shoes",
    subMenuLinks: [
      { text: "Trainers", link: "/shop" },
      { text: "Sandals", link: "/shop" },
      { text: "Heel shoes", link: "/shop" },
      { text: "Flat shoes", link: "/shop" },
      { text: "Special prices", link: "/shop" },
    ],
    width: 475,
    height: 800,
  },
  {
    id: 4,
    imgSrc: "/assets/images/home/demo7/slider4.jpg",
    title: "Accessories",
    width: 475,
    height: 800,
  },
  {
    id: 1,
    imgSrc: "/assets/images/home/demo7/slider1.jpg",
    title: "New In",
    width: 475,
    height: 800,
  },
  {
    id: 2,
    imgSrc: "/assets/images/home/demo7/slider2.jpg",
    title: "Coats",
    width: 475,
    height: 800,
  },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo7/slider3.jpg",
    title: "Shoes",
    subMenuLinks: [
      { text: "Trainers", link: "/shop" },
      { text: "Sandals", link: "/shop" },
      { text: "Heel shoes", link: "/shop" },
      { text: "Flat shoes", link: "/shop" },
      { text: "Special prices", link: "/shop" },
    ],
    width: 475,
    height: 800,
  },
  {
    id: 4,
    imgSrc: "/assets/images/home/demo7/slider4.jpg",
    title: "Accessories",
    width: 475,
    height: 800,
  },
];
export const slidesData3 = [
  {
    id: 3,
    imageSrc: "/assets/images/home/demo8/raffle-draw.jpg",
    season: "",
    // objectPosition: "50% center",
    title: "",
    subtitle: "",
    href: "/shop/",
  },
  {
    id: 1,
    imageSrc: "/assets/images/home/demo8/avif/Ignite-rose-web-Banner.avif",
    season: "Tailored Elegance",
    objectPosition: "50% center",
    title: "Feel Luxurious",
    subtitle: "with",
    href: "shop/perfumes/occidental-fragrance/ignite-rose",
  },
  {
    id: 2,
    imageSrc: "/assets/images/zumar-bnr.jpg",
    season: "Lasting",
    objectPosition: "70% center",
    title: "Redefines luxury &",
    subtitle: "evokes the spirit of royalty",
    href: "shop/perfumes/occidental-fragrance/zumar",
  },
  
];
export const slidesData33 = [
  {
    id: 3,
    imageSrc: "/assets/images/home/demo8/raffle-draw-mobile-new.jpg",
    season: "",
    objectPosition: "50% center",
    title: "",
    subtitle: "",
    href: "/shop/",
  },
  {
    id: 2,
    imageSrc: "/assets/images/zumar-bnr.jpg",
    season: "Lasting",
    objectPosition: "70% center",
    title: "Redefines luxury &",
    subtitle: "evokes the spirit of royalty",
    href: "shop/perfumes/occidental-fragrance/zumar",
  },

];
export const slidesData4 = [
  {
    id: 1,
    imageSrc: "/assets/images/home/demo9/slider1.jpg",
    imageAlt: "TRENDING 2023",
    subtitle: "Interior Designs",
    description:
      "Lorem ipsum dolor sit amet, consectetur elit. Odio pulvinar in ipsum amet.",
  },
  {
    id: 2,
    imageSrc: "/assets/images/home/demo9/slider1.jpg",
    imageAlt: "TRENDING 2023",
    subtitle: "Interior Designs",
    description:
      "Lorem ipsum dolor sit amet, consectetur elit. Odio pulvinar in ipsum amet.",
  },
  {
    id: 3,
    imageSrc: "/assets/images/home/demo9/slider1.jpg",
    imageAlt: "TRENDING 2023",
    subtitle: "Interior Designs",
    description:
      "Lorem ipsum dolor sit amet, consectetur elit. Odio pulvinar in ipsum amet.",
  },
  {
    id: 4,
    imageSrc: "/assets/images/home/demo9/slider1.jpg",
    imageAlt: "TRENDING 2023",
    subtitle: "Interior Designs",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio pulvinar in ipsum amet.",
  },
];
export const slidesData5 = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo10/slideshow-character1.png",
    imgAlt: "Woman Fashion 1",
    title: "Modern Jogger",
    price: "399,50 TL",
    type: "New Arrivals",
  },
  {
    id: 2,
    imgSrc: "/assets/images/home/demo10/slideshow-character1.png",
    imgAlt: "Woman Fashion 1",
    title: "Modern Jogger",
    price: "399,50 TL",
    type: "New Arrivals",
  },
  {
    id: 3,
    imgSrc: "/assets/images/home/demo10/slideshow-character1.png",
    imgAlt: "Woman Fashion 1",
    title: "Modern Jogger",
    price: "399,50 TL",
    type: "New Arrivals",
  },
];
export const slideData6 = [
  {
    id: 1,
    src: "/assets/images/home/demo11/slideshow-character1.png",
    alt: "Woman Fashion 1",
    category: "TOP SMARTPHONES",
    price: 279,
    feature1: "Heavy on features.",
    feature2: "Light on price.",
  },
  {
    id: 2,
    src: "/assets/images/home/demo11/slideshow-character1.png",
    alt: "Woman Fashion 1",
    category: "TOP SMARTPHONES",
    price: 279,
    feature1: "Heavy on features.",
    feature2: "Light on price.",
  },
  {
    id: 3,
    src: "/assets/images/home/demo11/slideshow-character1.png",
    alt: "Woman Fashion 1",
    category: "TOP SMARTPHONES",
    price: 279,
    feature1: "Heavy on features.",
    feature2: "Light on price.",
  },
  {
    id: 4,
    src: "/assets/images/home/demo11/slideshow-character1.png",
    alt: "Woman Fashion 1",
    category: "TOP SMARTPHONES",
    price: 279,
    feature1: "Heavy on features.",
    feature2: "Light on price.",
  },
];

export const slidesData7 = [
  {
    id: 1,
    backgroundColor: "#f5e6e0",
    imageUrl: "/assets/images/home/demo12/slideshow-pattern.png",
    imgUrl2: "/assets/images/home/demo12/slideshow-character1.png",
    alt: "Pattern",
    title: "Shop Our Freshest",
    subtitle1: "Fresh Hand-Picked Vegetables",
    subtitle2: "Everyday",
  },
  {
    id: 1,
    backgroundColor: "#f5e6e0",
    imageUrl: "/assets/images/home/demo12/slideshow-pattern.png",
    imgUrl2: "/assets/images/home/demo12/slideshow-character1.png",
    alt: "Pattern",
    title: "Shop Our Freshest",
    subtitle1: "Fresh Hand-Picked Vegetables",
    subtitle2: "Everyday",
  },
  {
    id: 1,
    backgroundColor: "#f5e6e0",
    imageUrl: "/assets/images/home/demo12/slideshow-pattern.png",
    imgUrl2: "/assets/images/home/demo12/slideshow-character1.png",
    alt: "Pattern",
    title: "Shop Our Freshest",
    subtitle1: "Fresh Hand-Picked Vegetables",
    subtitle2: "Everyday",
  },
  // Add more slide data objects as needed
];

export const slideData8 = [
  {
    id: 1,
    backgroundColor: "#f5e6e0",
    imgSrc: "/assets/images/home/demo13/slider1.jpg",
    imgAlt: "Pattern",
    title1: "EYEWEAR",
    title2: "UP TO $60 OFF POLARISED",
    title3: "SUNGLASSES",
  },
  {
    id: 2,
    backgroundColor: "#f5e6e0",
    imgSrc: "/assets/images/home/demo13/slider1.jpg",
    imgAlt: "Pattern",
    title1: "EYEWEAR",
    title2: "UP TO $60 OFF POLARISED",
    title3: "SUNGLASSES",
  },
];

export const slideData9 = [
  {
    id: 1,
    bgImg: "/assets/images/home/demo14/slider1_bg.jpg",
    characterImg: "/assets/images/home/demo14/slider1_character.png",
    title: "Search Lab N95 Face Mask",
    subtitle: "Just a few seconds to measure your body temperature.",
    btnLink: "/shop",
  },
  {
    id: 2,
    bgImg: "/assets/images/home/demo14/slider1_bg.jpg",
    characterImg: "/assets/images/home/demo14/slider2_character.png",
    title: "Search Lab N95 Face Mask",
    subtitle: "Just a few seconds to measure your body temperature.",
    btnText: "SHOP NOW",
  },
];

export const slideData10 = [
  {
    id: 1,
    bgImage: "/assets/images/home/demo15/slider1_bg.jpg",
    title: "Ahmed Perfumes",
    description:
      "Beaux products protect, moisturize, and lubricate your skin. It smartly nourish your skin. with lotions, day creams, night creams, tinted moisturizers, and more.",
  },
  {
    id: 2,
    bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
    title: "Buy Perfumes",
    description:
      "Beaux products protect, moisturize, and lubricate your skin. It smartly nourish your skin. with lotions, day creams, night creams, tinted moisturizers, and more.",
  },
];

export const slideData1000 = [
  {
    id: 1,
    bgImage: "/assets/images/home/demo15/slider1_bg.jpg",
    title: "Natural Glow",
    description:
      "Free Shipping on orders AED 400 and above",
  },
  {
    id: 2,
    bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
    title: "Natural Glow",
    description:
      "A huge selection of best fragrance",
  },
  // {
  //   id: 3,
  //   bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
  //   title: "Natural Glow",
  //   description:
  //     "FOR ANY INQUIRIES, PLEASE CONTACT OUR CUSTOMER CARE TEAM AT +800 472 1828",
  // },
  {
    id: 3,
    bgImage: "/assets/images/home/demo15/slider2_bg.jpg",
    title: "Natural Glow",
    description:
      "Purchase above AED 250 and get a chance to WIN big",
  },
];


  // Add more slide objects as needed

